<template>
  <FormCard
    :is-loading="isLoading"
    @click:cancel="onCancel"
    @click:save="onSave"
  >
    <template #content>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <VText
          v-model="v.name.$model"
          :label="$t('app.name')"
          class="col-span-2"
          :errors="v.name.$errors"
          @keyup.enter="onSave"
        />

        <VText
          v-model="v.code.$model"
          :label="$t('app.code')"
          :errors="v.code.$errors"
          @keyup.enter="onSave"
        >
          <template #hint>
            {{ $t("hints.administration_sites_code") }}
          </template>
        </VText>

        <VText
          v-model="v.external_code.$model"
          :label="$t('app.external_code')"
          :errors="v.external_code.$errors"
          @keyup.enter="onSave"
        >
          <template #hint>
            {{ $t("hints.administration_sites_external_code") }}
          </template>
        </VText>

        <VWysiwyg
          v-model="v.address.$model"
          :label="$t('app.address')"
          class="col-span-2"
          :errors="v.address.$errors"
        />

        <VColor
          v-model="v.colour.$model"
          :label="$t('app.colour')"
          :errors="v.colour.$errors"
          @keyup.enter="onSave"
        />

        <VSwitch
          v-model="v.status.$model"
          :label="$t('app.status')"
          true-value="Active"
          false-value="Inactive"
          :errors="v.status.$errors"
        />
      </div>
    </template>
  </FormCard>
</template>

<script>
// Composables
import useForm from "@/composables/useForm";
import useSite from "@/composables/useSite";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";

export default {
  mixins: [FormMixin],
  setup(props, context) {
    const { value, rules, options } = useSite();
    const { v, onSave, onCancel } = useForm(props, context, { value, rules });

    return {
      // useSite
      value,
      options,
      // useForm
      v,
      onSave,
      onCancel
    };
  }
};
</script>
