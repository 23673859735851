<template>
  <div class="flex items-center">
    <input
      :id="id"
      :disabled="disabled || readonly"
      class="form-check-input mr-2"
      type="checkbox"
      :checked="isValueChecked"
      :class="[inputClass, { 'border-theme-6': hasErrors }]"
      @input="onInput"
    />

    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label mb-0">
        {{ label }}
      </label>
    </slot>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { computed } from "vue";
// Mixins
import InputMixin from "@/mixins/InputMixin";

export default {
  mixins: [InputMixin],
  props: {
    trueValue: {
      type: [String, Boolean],
      default: true
    },
    falseValue: {
      type: [String, Boolean],
      default: false
    }
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    // Computed
    const isValueChecked = computed(() => {
      return props.modelValue === props.trueValue;
    });

    // Methods
    const onInput = e => {
      const isChecked = e.target.checked;
      const value = isChecked ? props.trueValue : props.falseValue;
      context.emit("update:modelValue", value);
    };

    return {
      isValueChecked,
      onInput
    };
  }
};
</script>
